import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyRichText } from "../../../slices/PageDataBodyRichText"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyRichText
				text={
					[
						{ type: "heading2", text: lorem.sentences(1), spans: [] },
						{ type: "paragraph", text: lorem.paragraphs(1), spans: [] },
						{ type: "heading3", text: lorem.sentences(1), spans: [] },
						{ type: "paragraph", text: lorem.paragraphs(1), spans: [] },
						{ type: "heading4", text: lorem.sentences(1), spans: [] },
						{ type: "paragraph", text: lorem.paragraphs(1), spans: [] },
						{ type: "paragraph", text: lorem.paragraphs(1), spans: [] },
						{ type: "list-item", spans: [], text: lorem.sentences(1) },
						{ type: "list-item", spans: [], text: lorem.sentences(1) },
						{ type: "list-item", spans: [], text: lorem.sentences(1) },
						{ type: "list-item", spans: [], text: lorem.sentences(1) },
						{ type: "heading5", text: lorem.sentences(1), spans: [] },
						{ type: "o-list-item", spans: [], text: lorem.sentences(1) },
						{ type: "o-list-item", spans: [], text: lorem.sentences(1) },
						{ type: "o-list-item", spans: [], text: lorem.sentences(1) },
						{ type: "o-list-item", spans: [], text: lorem.sentences(1) },
					] as pt.RichTextField
				}
			/>
		</CmsGuideLayout>
	)
}

export default Component
